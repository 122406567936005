import React from "react";
import { useTranslation } from "react-i18next";
import "./LanguageSwitcher.scss";

function LanguageSwitcher() {
  const { t, i18n } = useTranslation("global");

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="language-switcher">
      <span>{t("main.headers.DefaultLanguage")}</span>
      <select
        className="language-switcher__select"
        onChange={changeLanguage}
        defaultValue={i18n.languages[1]}
      >
        <option value="en">English</option>
        <option value="es">Español</option>
        <option value="pt">Português</option>
      </select>
    </div>
  );
}

export default LanguageSwitcher;
