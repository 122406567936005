import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.scss";
import { FaBars, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const { t } = useTranslation("global");
  return (
    <header>
      <nav className="nav-elements">
        <div
          className={`nav-elements__container  ${
            showNavbar ? "responsive_nav" : ""
          }`}
        >
          <ul className="nav-elements__ul">
            <li className="nav-elements__li">
              <NavLink
                onClick={() => setShowNavbar(false)}
                className="nav-elements__link"
                to="/"
              >
                {t("main.navBar.home")}
              </NavLink>
            </li>
            <li className="nav-elements__li">
              <NavLink
                onClick={() => setShowNavbar(false)}
                className="nav-elements__link"
                to="contact"
              >
                {t("main.navBar.contactUs")}
              </NavLink>
            </li>
            <li className="nav-elements__li">
              <NavLink
                onClick={() => setShowNavbar(false)}
                className="nav-elements__link"
                to="about-us"
              >
                {t("main.navBar.aboutUs")}
              </NavLink>
            </li>
          </ul>
          <button
            className="nav-elements__btn nav-elements__btn-close"
            onClick={() => setShowNavbar(false)}
          >
            <FaTimes />
          </button>
        </div>
      </nav>
      {!showNavbar && (
        <button
          className="nav-elements__btn"
          onClick={() => setShowNavbar(true)}
        >
          <FaBars />
        </button>
      )}
    </header>
  );
};
export default Navbar;
