import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const InternationalizationProvider = ({ children }) => {
  const { i18n } = useTranslation("global");

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, [i18n]);

  return <>{children}</>;
};

export default InternationalizationProvider;
