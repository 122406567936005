import React from "react";
import "./LoadingSpinner.scss";
const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner-container__spinner"></div>
      <div className="spinner-container__no-animation">Tuareg</div>
      <div className="spinner-container__no-animation-backgroud"></div>
      <div className="spinner-container__no-animation-backgroud2"></div>
    </div>
  );
};

export default LoadingSpinner;
