import React from "react";
import logo from "../../../img/logo2-white.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../utils/LanguageSwitcher/LanguageSwitcher";
// import SocialMediaIcons from "../../utils/SocialMediaIcons/SocialMediaIcons";

import("./Footer.scss");
const Footer = () => {
  const { t } = useTranslation("global");
  const fechaActual = new Date();
  const anioActual = fechaActual.getFullYear();

  return (
    <div className="footer">
      <div className="footer__logo-container">
        <div className="footer__logo">
          <Link to="/">
            <img className="footer__logo-image" src={logo} alt="logo" />
          </Link>
        </div>
      </div>
      <div className="footer__footer-container">
        <div className="footer__col footer__col1">
          <p>© {anioActual} Tuareg Comercio Exterior</p>
        </div>

        <div className="footer__col footer__col2">
          {/* <SocialMediaIcons /> */}
          <ul className="footer__link-container">
            <li className="footer__link">
              <Link to="contact">{t("main.navBar.contactUs")}</Link>
            </li>
            <span>|</span>
            <li className="footer__link">
              <Link to="about-us">{t("main.navBar.aboutUs")}</Link>
            </li>
            <span>|</span>
            <li className="footer__link">
              <Link to="privacy-policy">{t("main.navBar.PrivacyPolicy")}</Link>
            </li>
          </ul>
        </div>
        <div className="footer__col footer__col3">
          <LanguageSwitcher />
        </div>
      </div>
      <div className="footer__sub-footer">
        <p className="footer__sub-footer-p">
          São Miguel do Iguaçu, Rua Torres 587, Paraná, Brazil - CEP: 85877-000
        </p>
        <p className="footer__sub-footer-p">
          Tuareg Comercio Exterior - CNPJ: 43.887.344/0001-77
        </p>
      </div>
    </div>
  );
};
export default Footer;
