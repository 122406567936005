import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Navbar from "./components/common/Navbar/Navbar";
import Footer from "./components/common/Footer/Footer";
import "./i18n";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import InternationalizationProvider from "./components/utils/InternationalizationProvider";
import { Suspense, lazy } from "react";
import LoadingSpinner from "./components/utils/LoadingSpinner/LoadingSpinner";
import Error404 from "./pages/404/Error404";
const Home = lazy(() => import("./pages/home/Home"));
const Contact = lazy(() => import("./pages/contact/Contact"));
const AboutUs = lazy(() => import("./pages/aboutUs/AboutUs"));
const PrivacyPolicy = lazy(() => import("./pages/privacyPolicy/PrivacyPolicy"));

function App() {
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <InternationalizationProvider>
          <Suspense fallback={<LoadingSpinner />}>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
            <Footer />
          </Suspense>
        </InternationalizationProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
}

export default App;
