import React from "react";
import { FaExclamationTriangle, FaHome } from "react-icons/fa";
import "./Error404.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Error404 = () => {
  const { t } = useTranslation("global");
  return (
    <div className="error-container">
      <div className="error-container__content">
        <FaExclamationTriangle className="error-container__error-icon" />
        <h1 className="error-container__h1">{t("404Error.title-1")}</h1>
        <p className="error-container__p">{t("404Error.paragraph")}</p>
        <Link to="/" className="error-container__home-link">
          <FaHome className="error-container__home-icon" />
          {t("404Error.button")}
        </Link>
      </div>
    </div>
  );
};

export default Error404;
